<template>
    <div class="dating-sincerity">
        <StackRouterHeaderBar />
        <main class="main">
            <FeedbackTop
                class="m-b-40"
                :title="`${
                    user.name || user.profile.nickname
                }님은<br /><span class='c-primary f-bold'>진정성 있는</span> 분이었나요?`"
            />
            <FeedbackOptionItems v-model="items" @select="onSelectItems" />
        </main>
    </div>
</template>

<script>
import FeedbackTop from './components/FeedbackTopComponent'
import FeedbackOptionItems from './components/FeedbackOptionItems'

export default {
    name: 'DatingSincerityPage',
    components: { FeedbackTop, FeedbackOptionItems },
    props: {
        user: Object,
    },
    data: () => ({
        items: [],
    }),
    methods: {
        async init() {
            try {
                const options = await this.$store.dispatch('filterFeedbackOptionByCategory', 'is_sincerity')
                this.items = options.map(option => {
                    this.$set(option, 'selected', false)
                    return option
                })
            } catch (e) {}
        },
        onSelectItems(idx) {
            this.$store.commit('setSelectedFeedbacks', {
                category: 'sincerity',
                feedbacks: [{ id: this.items[idx].id }],
            })
            // 진정성 없다고 한 경우
            if (this.items[idx].id === 41) {
                this.$stackRouter.push({
                    name: 'DatingInsincerePage',
                    props: { user: this.user },
                })
            } else {
                this.$stackRouter.push({
                    name: 'DatingMannerPage',
                    props: { user: this.user },
                })
            }
        },
    },
    mounted() {
        this.init()
    },
}
</script>

<style lang="scss" scoped>
.dating-sincerity {
    .main {
        padding: 0 16px;
    }
}
</style>
